import { Effect, Reducer } from 'umi';
import { announcementsRead, announcementsReadAll, noticeRead, noticeReadAll, queryAnnouncements, queryAsyncTask, queryEnums, queryNotices } from '@/api/core/service/GlobalService';

import moment from 'moment';

export interface OssPresignInfo<PlatformInfo extends any> {
    filename: string,
    originalFilename: string,
    fileKey: string,
    bucket: string,
    dir: string,
    exist: boolean,
    accessUrl: string,
    platformInfo: PlatformInfo
}

export interface AliyunOssPresignInfo extends OssPresignInfo<AliyunOssPolicyResult> {

}

export interface CtyunOssPresignInfo extends OssPresignInfo<CtyunOssPolicyResult> {

}

export interface AliyunOssPolicyResult {
    dir: string, //'user-dir/',
    expire: string, //'1577811661',
    host: string, //'//www.mocky.io/v2/5cc8019d300000980a055e76',
    accessKeyId: string, //'c2hhb2RhaG9uZw==',
    policy: string, //'eGl4aWhhaGFrdWt1ZGFkYQ==',
    signature: string, //'ZGFob25nc2hhbw==',
    callback: string, //'ZGFob25nc2hhbw==',
}

export interface AliyunOssCallbackResult {
    bucket: string,
    filename: string,
    size: string,
    mimeType: string,
    width: string,
    height: string,
    originalFilename: string,
}

export interface CtyunOssPolicyResult {
    uploadUrl: string;
}

export interface NoticeItem {
    id: string;
    type: string;
    status: string;
    action: string;
    channels: string;
    createTime: string;
    deleted: boolean;
    extras: string | null;
    message: string;
    modifyTime: string;
    objectId: string;
    objectType: string;
    readTime: string;
    recipientId: string;
    senderId: string;
}

export interface AppVersionLast {
    id: string
    /**版本号*/
    version: string
    /**描述*/
    description: string
    /**文件路径*/
    filePath: string
}


export interface AnnouncementItem {
    createTime: string;
    deleted: boolean;
    id: number;
    modifyTime: string;
    read: boolean;
    senderId: number;
    title: string;
}

export interface ValidateMessages {
    [key: string]: any;
}

export interface ProcessingResult {
    description: string;
    exportUrl: string;
    importUrl: string;
    success: boolean;
    validateMessages: ValidateMessages;
}

export interface AsyncTask {
    createTime: string;
    createUserId: number;
    id: number;
    processingResult: ProcessingResult;
    progress: number;
    scope: string;
    status: string;
    taskName: string;
    taskType: string;
    taskUniqueFlag: string;

    [key: string]: any;
}

export interface WxJsapiSignature {
    appId: string;
    nonceStr: string;
    signature: string;
    timestamp: string;
    url: string;
}

export interface KfEnum {
    enumType: string; //枚举名
    enums: KfEnumItem[]; //枚举描述
}

export interface KfEnumItem {
    enumName: string; //枚举名
    enumDesc: string; //枚举描述
    enumColor: string; //枚举描述
}

export interface KfDict {
    name: string; //字典名
    val: string; //字典值
}


export type EnumObj = { [key: string]: KfEnumItem };
export type EnumsType = { [key: string]: EnumObj };

export interface GlobalModelState {
    collapsed?: boolean;
    enums: EnumsType;
    notices?: {
        noticesData?: NoticeItem[];
        unreadCount?: number;
    };
    announcements?: {
        announcementsData?: AnnouncementItem[];
        unreadCount?: number;
    };
    asyncTask?: any;
    asyncTaskBystandAlonePage?: any;
}

export interface GlobalModelType {
    namespace: 'global';
    state: GlobalModelState;
    effects: {
        fetchEnums: Effect;
        fetchAsyncTask: Effect;
        fetchAsyncTaskBystandAlonePage: Effect;
        fetchNotices: Effect;
        fetchAnnouncement: Effect;
        changeNoticeReadState: Effect;
        changAnnouncementsReadState: Effect;
        clearNotices: Effect;
        clearAnnouncements: Effect;
        changeAsyncTasks: Effect;
    };
    reducers: {
        changeLayoutCollapsed: Reducer<GlobalModelState>;
        saveNotices: Reducer<GlobalModelState>;
        saveAnnouncement: Reducer<GlobalModelState>;
        saveClearedNotices: Reducer<GlobalModelState>;
        saveClearAnnouncements: Reducer<GlobalModelState>;
        saveEnums: Reducer<GlobalModelState>;
        saveAsyncTask: Reducer<GlobalModelState>;
        saveAsyncTaskBystandAlonePage: Reducer<GlobalModelState>;
    };
}

const GlobalModel: GlobalModelType = {
    namespace: 'global',

    state: {
        collapsed: false,
        enums: {},
        notices: {},
        announcements: {},
        asyncTask: {},
    },

    effects: {
        * fetchEnums(_, { call, put }) {
            const response = yield call(queryEnums);
            yield put({
                type: 'saveEnums',
                payload: response,
            });
        },
        * fetchAsyncTask({ payload }, { call, put }) {
            const res = yield call(queryAsyncTask, {
                page: payload.page,
                pageSize: payload.pageSize,
                taskTypes: payload.taskTypes,
                createdAfter: moment()?.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
            });
            yield put({
                type: 'saveAsyncTask',
                payload: res,
            });
        },
        * fetchAsyncTaskBystandAlonePage({ payload }, { call, put }) {
            const res = yield call(queryAsyncTask, {
                page: payload.page,
                pageSize: payload.pageSize,
                taskTypes: payload.taskTypes,
                createdAfter: moment()?.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
            });
            yield put({
                type: 'saveAsyncTaskBystandAlonePage',
                payload: res,
            });
        },

        * fetchNotices({ payload }, { call, put }) {
            const res = yield call(queryNotices, { page: payload.page, pageSize: payload.pageSize });
            yield put({
                type: 'saveNotices',
                payload: {
                    data: res.data,
                    total: res.total,
                    extras: res.extras,
                },
            });
        },

        * changeNoticeReadState({ payload }, { call, put, select }) {
            const mapData = yield select((state: any) => {
                return state.global.notices.data.map((item: any) => {
                    const notice = { ...item };
                    if (notice.id === payload) {
                        notice.status = 'READ';
                    }
                    return notice;
                });
            });

            const res = yield call(noticeRead, payload);

            if (res.flag) {
                const results = yield call(queryNotices);

                yield put({
                    type: 'saveNotices',
                    payload: {
                        data: mapData,
                        total: results.total,
                        extras: results.extras,
                    },
                });
            }
        },

        * fetchAnnouncement({ payload }, { call, put }) {
            const announcementResData = yield call(queryAnnouncements, { page: payload.page, pageSize: payload.pageSize });

            yield put({
                type: 'saveAnnouncement',
                payload: {
                    data: announcementResData.data,
                    total: announcementResData.total,
                    extras: announcementResData.extras,
                },
            });
        },

        * changAnnouncementsReadState({ payload }, { call, put, select }) {
            const mapData = yield select((state: any) => {
                return state.global.announcements.data.map((item: any) => {
                    const announcement = { ...item };
                    if (announcement.id === payload) {
                        announcement.read = true;
                    }
                    return announcement;
                });
            });

            const announcementsRes = yield call(announcementsRead, payload);

            if (announcementsRes.flag) {
                const announcementResData = yield call(queryAnnouncements);
                yield put({
                    type: 'saveAnnouncement',
                    payload: {
                        data: mapData,
                        total: announcementResData.total,
                        extras: announcementResData.extras,
                    },
                });
            }
        },

        * clearNotices({ payload }, { call, put, select }) {
            const mapData = yield select((state: any) => {
                return state.global.notices.data.map((item: any) => {
                    const notice = { ...item };
                    if (notice) {
                        notice.status = 'READ';
                    }
                    return notice;
                });
            });

            const clearNoticesRes = yield call(noticeReadAll);

            if (clearNoticesRes.flag) {
                const results = yield call(queryNotices);
                const { code, total, extras } = results;

                yield put({
                    type: 'saveClearedNotices',
                    payload: {
                        data: mapData,
                        total: total,
                        extras: extras,
                    },
                });

                if (code === 200) {
                    return true;
                }
            }
            return false;
        },
        * clearAnnouncements({ payload }, { call, put, select }) {
            const mapData = yield select((state: any) => {
                return state.global.announcements.data.map((item: any) => {
                    const announcement = { ...item };
                    if (announcement) {
                        announcement.read = true;
                    }
                    return announcement;
                });
            });

            const clearAnnouncementsRes = yield call(announcementsReadAll);

            if (clearAnnouncementsRes.flag) {
                const announcementResData = yield call(queryAnnouncements);
                const { code, total, extras } = announcementResData;

                yield put({
                    type: 'saveClearAnnouncements',
                    payload: {
                        data: mapData,
                        total: total,
                        extras: extras,
                    },
                });
                if (code === 200) {
                    return true;
                }
            }
            return false;
        },

        * changeAsyncTasks({ payload }, { call, put }) {
            yield put({
                type: 'saveAsyncTask',
                payload: payload,
            });
        },
    },

    reducers: {
        changeLayoutCollapsed(state = { enums: {}, collapsed: true, notices: {} }, { payload }): GlobalModelState {
            return {
                ...state,
                collapsed: payload,
            };
        },
        saveNotices(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                notices: payload,
            };
        },
        saveAnnouncement(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                announcements: payload,
            };
        },

        saveClearedNotices(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                notices: payload,
            };
        },
        saveClearAnnouncements(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                announcements: payload,
            };
        },
        saveAsyncTask(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                asyncTask: payload,
            };
        },
        saveAsyncTaskBystandAlonePage(state, { payload }): GlobalModelState {
            return {
                enums: {},
                ...state,
                collapsed: false,
                asyncTaskBystandAlonePage: payload,
            };
        },
        saveEnums(state, { payload }) {
            const kfEnums = payload.data as KfEnum[];

            const enums = {};
            kfEnums.forEach((enu: KfEnum) => {
                const opts = enu.enums;
                const mappingResult = {};
                opts.forEach((opt) => {
                    mappingResult[opt.enumName] = opt;
                });
                enums[enu.enumType] = mappingResult;
            });

            return {
                ...state,
                enums: enums,
            };
        },
    },
};

export default GlobalModel;
