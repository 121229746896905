/**
 * 首页统计分析
 */

import type { AgeCenterDataType, DetailList } from './AgeCenter';
import type { Effect, GlobalModelType, Reducer } from 'umi'
import { queryAgeCenters, queryAges, queryBases, queryBasesbContinent, queryGeneralBases, queryGroupBases, queryImgs, queryOverviews, queryPark, querySexs } from '../service/AnalysisService'

import type { AgeDataType } from './Age';
import type { BaseDataType } from './Base';
import type { ContinentDataType } from './ContinentDataType';
import type { GeneralBaseDataType } from './GeneralBase';
import type { ImgDataType } from './Imgs';
import NP from 'number-precision'
import type { OverviewDataType } from './Overview';
import type { Panda } from '@/api/panda/archives/model/Panda';
import type { RespPageResult } from '@/api/core/model/ReqData';
import type { SexDataType } from './Sex';
import type { WeatherDataType } from './Weather';
import _ from 'lodash';
import { queryPandas } from '@/api/panda/archives/service/PandaService';

interface ageCenterCustomData {
    metaData: DetailList[],
    totalNum: number,
}

export interface AnalysisStateType {
    overviewData?: OverviewDataType
    baseData?: BaseDataType[]
    parkData?: any[]
    pandaData?: RespPageResult<Panda>[]
    ageData?: AgeDataType[]
    sexData?: SexDataType[]
    cooperatorData?: ContinentDataType[]
    cooperatorAgeData?: AgeDataType[]
    cooperatorSexData?: SexDataType[]
    generalBaseData?: GeneralBaseDataType[]
    ageCenterData?: ageCenterCustomData
    imagData?: ImgDataType[]
    weatherData?: WeatherDataType
}

export interface AnalysisModelType {
    namespace: string;
    state: AnalysisStateType;
    effects: {
        getOverView: Effect;
        getBase: Effect;
        getPark: Effect;
        getAge: Effect;
        getSex: Effect;
        getCooperators: Effect;
        getCooperatorsAge: Effect;
        getCooperatorsSex: Effect;
        getPandas: Effect;
        getGeneralBase: Effect;
        getAgeCenter: Effect;
        getImags: Effect;
        getGroupBases: Effect;
        clearData: Effect
    };
    reducers: {
        saveOverviewData: Reducer<AnalysisStateType>;
        saveBaseData: Reducer<AnalysisStateType>;
        saveParkData: Reducer<AnalysisStateType>;
        savePandaData: Reducer<AnalysisStateType>;
        saveAgeData: Reducer<AnalysisStateType>;
        saveSexData: Reducer<AnalysisStateType>;
        saveCooperatorData: Reducer<AnalysisStateType>;
        saveCooperatorAgeData: Reducer<AnalysisStateType>;
        saveCooperatorSexData: Reducer<AnalysisStateType>;
        saveGeneralBaseData: Reducer<AnalysisStateType>;
        saveAgeCenterData: Reducer<AnalysisStateType>;
        saveImgData: Reducer<AnalysisStateType>;
        saveGroupBasesData: Reducer<AnalysisStateType>;
        emptyData: any
    };
}


const AnalysisModel: AnalysisModelType = {
    namespace: 'analysis',

    state: {
        overviewData: {} as OverviewDataType,
        baseData: [] as BaseDataType[],
        parkData: [] as any[],
        pandaData: [] as RespPageResult<Panda>[],
        ageData: [] as AgeDataType[],
        sexData: [] as SexDataType[],
        cooperatorData: [] as ContinentDataType[],
        cooperatorAgeData: [] as AgeDataType[],
        cooperatorSexData: [] as SexDataType[],
        generalBaseData: [] as GeneralBaseDataType[],
        ageCenterData: {} as ageCenterCustomData,
        imagData: [] as ImgDataType[],
        weatherData: {} as WeatherDataType,
    },

    effects: {
        * getOverView({ payload }, { call, put }) {
            let response: any = null

            response = yield call(queryOverviews, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveOverviewData',
                payload: response.data,
            });
        },

        * getBase({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryBases, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveBaseData',
                payload: response.data,
            });
        },
        * getPark({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryPark, { page: 1, pageSize: 20, ...payload })

            yield put({
                type: 'saveParkData',
                payload: response.data,
            });
        },
        * getPandas({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryPandas, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'savePandaData',
                payload: response,
            });
        },
        * getAge({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryAges, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveAgeData',
                payload: response.data,
            });
        },
        * getSex({ payload }, { call, put }) {
            let response: any = null
            response = yield call(querySexs, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveSexData',
                payload: response.data,
            });
        },
        * getCooperators({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryBasesbContinent, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveCooperatorData',
                payload: response.data,
            });
        },
        * getCooperatorsAge({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryAges, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveCooperatorAgeData',
                payload: response.data,
            });
        },
        * getCooperatorsSex({ payload }, { call, put }) {
            let response: any = null
            response = yield call(querySexs, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveCooperatorSexData',
                payload: response.data,
            });
        },
        * getGeneralBase({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryGeneralBases, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveGeneralBaseData',
                payload: response.data,
            });
        },
        * getAgeCenter({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryAgeCenters, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveAgeCenterData',
                payload: response.data,
            });
        },
        * getImags({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryImgs, { page: 1, pageSize: 5, ...payload })
            yield put({
                type: 'saveImgData',
                payload: response.data,
            });
        },
        * getGroupBases({ payload }, { call, put }) {
            let response: any = null
            response = yield call(queryGroupBases, { page: 1, pageSize: 20, ...payload })
            yield put({
                type: 'saveGroupBasesData',
                payload: response.data,
            });
        },
        * clearData({ payload }, { call, put }) {
            yield put({
                type: 'emptyData',
                payload: null,
            })
        }
    },
    reducers: {
        saveOverviewData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                overviewData: payload as OverviewDataType,
            }
        },
        saveBaseData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                baseData: payload?.baseDetailList?.map(b => ({ type: b.baseName, value: b.pandaNum, baseId: b.baseId })) as BaseDataType[],
            }
        },
        saveParkData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                parkData: payload?.detailList?.map(b => ({ type: b.parkName, value: b.pandaNum, baseId: b.parkId })) as any[],
            }
        },
        savePandaData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                pandaData: payload as RespPageResult<Panda>[],
            }
        },
        saveAgeData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                ageData: payload?.detailList?.map(b => ({
                    type:
                        b.agePeriod === 'A' ? '幼年'
                            :
                            b.agePeriod === 'B' ? '亚成体'
                                :
                                b.agePeriod === 'C' ? '成年'
                                    : b.agePeriod === 'D' ? '老年' : '', value: b.pandaNum, agePeriod: b.agePeriod
                })) as AgeDataType[],
            }
        },
        saveSexData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                sexData: payload?.detailList?.map(b => ({ type: b.sex === 'MALE' ? '雄性' : b.sex === null ? '未知' : '雌性', value: b.pandaNum, sex: b.sex })) as SexDataType[],
            }
        },
        saveCooperatorData(state, { payload }): AnalysisStateType {

            const arr: any = []

            payload?.continentList?.forEach(element => {
                element.countryList.forEach(c => {
                    arr.push({
                        type: c.country,
                        value: c.pandaNum
                    })
                })
            });

            const china = arr.filter(f => f.type === "中国")
            const otherPandaNum: any = _.sumBy(arr.filter(f => f.type !== "中国"), (o: any) => o.value)


            const cooperatorData = [...china, {
                type: '其它',
                value: otherPandaNum,
            }] as ContinentDataType[]

            return {
                ...state,
                cooperatorData,
            }
        },
        saveCooperatorAgeData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                cooperatorAgeData: payload?.detailList.map(b => ({
                    type:
                        b.agePeriod === 'A' ? '幼年'
                            :
                            b.agePeriod === 'B' ? '亚成体'
                                :
                                b.agePeriod === 'C' ? '成年'
                                    : b.agePeriod === 'D' ? '老年' : '', value: b.pandaNum, agePeriod: b.agePeriod
                })) as AgeDataType[],
            }
        },
        saveCooperatorSexData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                cooperatorSexData: payload?.detailList.map(b => ({ type: b.sex === 'MALE' ? '雄性' : b.sex === null ? '未知' : '雌性', value: b.pandaNum, sex: b.sex })) as SexDataType[],
            }
        },
        saveGeneralBaseData(state, { payload }): AnalysisStateType {
            const arr = [
                ...payload?.map(v => {
                    return {
                        date: v.entityRecordDateStastring,
                        value: v.mentalStateNightGoodRatio,
                        category: '精神状态好'
                    }
                }),
                ...payload?.map(v => {
                    return {
                        date: v.entityRecordDateStastring,
                        value: v.appetiteNightGoodRatio,
                        category: '食欲情况好'
                    }
                }),
                ...payload?.map(v => {
                    return {
                        date: v.entityRecordDateStastring,
                        value: v.activitiesNightGoodRatio,
                        category: '活动情况好'
                    }
                })
            ]


            return {
                ...state,
                generalBaseData: arr as GeneralBaseDataType[],
            }
        },
        saveAgeCenterData(state, { payload }): AnalysisStateType {

            const metaData = payload?.map(d => {
                return d.ageDto.detailList.map(t => {
                    return {
                        ...t,
                        agePeriod: t.agePeriod === 'A' ? '幼年'
                            :
                            t.agePeriod === 'B' ? '亚成体'
                                :
                                t.agePeriod === 'C' ? '成年'
                                    : t.agePeriod === 'D' ? '老年' : '',
                        name: d.staName,
                    }
                })
            }).flat() as DetailList[]

            const obj = {
                metaData,
                totalNum: _.sumBy(metaData, (d) => d.pandaNum),

            }
            return {
                ...state,
                ageCenterData: obj as ageCenterCustomData
            }
        },
        saveImgData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                imagData: [...payload] as ImgDataType[],
            }
        },
        saveGroupBasesData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                weatherData: payload as WeatherDataType,
            }
        },
        emptyData(state, { payload }): AnalysisStateType {
            return {
                ...state,
                weatherData: null,
            }
        },
    }
};

export default AnalysisModel