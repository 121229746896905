import { reloadAuthorized } from './Authorized';

export interface AuthInfo {
    token: string;
    principal: any;
    authorities: string[];
}

export function getAuthority(): string[] {
    const authInfo = getAuthInfo();
    if (authInfo) {
        return authInfo.authorities;
    }
    return [];
}

export function hasAuthority(authority: string): boolean {
    return hasAnyAuthority(authority)
}

export function hasAnyAuthority(...authorities: string[]): boolean {
    let authoritys: string[] = [];
    const authInfo = getAuthInfo();
    if (authInfo) {
        //超级管理员
        if (authInfo.principal?.userId.toString() === "1") {
            return true;
        }
        authoritys = authInfo.authorities;
    }
    return !!authoritys.find(d => authorities.includes(d))
}

export function isSuperAdmin(): boolean {
    const authInfo = getAuthInfo();
    if (authInfo) {
        //超级管理员
        if (authInfo.principal?.userId.toString() === "1") {
            return true;
        }
    }
    return false;
}

export function getToken(): string | null {
    const authInfo = getAuthInfo();
    if (authInfo) {
        return authInfo.token;
    }
    return null;
}

export function getAuthInfo(): AuthInfo | null {
    const authInfoStr = localStorage ? localStorage.getItem("_authinfo_") : null;
    if (authInfoStr) {
        return JSON.parse(authInfoStr);
    }
    return null;
}

export function setAuthInfo(authInfo: AuthInfo): void {
    localStorage.setItem("_authinfo_", JSON.stringify(authInfo));
    // auto reload principal
    reloadAuthorized();
}

export function clearAuthInfo(): void {
    localStorage.removeItem("_authinfo_");

}
