import request from '@/utils/request';
import {
    EntityId,
    PageQueryParams,
    RespListResult,
    RespPageResult,
    RespResult,
} from '@/api/core/model/ReqData';
import {
    Menu_Create,
    Menu_Modify,
    Menu_Patch,
    Menu,
    Menu_TreeNode,
} from '@/api/system/model/Menu';
import {Area} from "@/api/system/model/Area";

import { MenuDataItem, MessageDescriptor, Route, RouterTypes, WithFalse } from '@ant-design/pro-layout/es/typings';

export interface MenuPageQueryParams extends Partial<PageQueryParams> {
    searchText?: string;
    parentId?: number;
}

export interface MenuTreeQueryParams {
    parentId?: number;
    timestamp?: string;
}

export async function queryMenus(params: MenuPageQueryParams): Promise<RespPageResult<Menu>> {
    return request('/api/v1/menu', {
        params,
    });
}

export async function queryMenuTree(
    params: MenuTreeQueryParams,
): Promise<RespListResult<Menu_TreeNode>> {
    return request('/api/v1/menu/tree', {
        params,
    });
}

export async function queryFrMenuTree(
    params: MenuTreeQueryParams,
): Promise<RespListResult<Route>> {
    return request('/api/v1/menu/fr-tree', {
        params,
    });
}

export async function queryMenu(bizId: EntityId): Promise<RespResult<Menu>> {
    return request(`/api/v1/menu/${bizId}`);
}

export async function createMenu(entity: Menu_Create): Promise<RespResult<any>> {
    return request(`/api/v1/menu`, {
        method: 'POST',
        data: entity,
    });
}

export async function modifyMenu(entity: Menu_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/menu/${entity.id}`, {
        method: 'PUT',
        data: entity,
    });
}

export async function patchMenu(entity: Menu_Patch): Promise<RespResult<any>> {
    return request(`/api/v1/menu/${entity.id}`, {
        method: 'PATCH',
        data: entity,
    });
}

export async function deleteMenu(bizId: EntityId): Promise<RespResult<any>> {
    return request(`/api/v1/menu/${bizId}`, {
        method: 'DELETE',
    });
}

export async function deleteMenus(bizIds: EntityId[]): Promise<RespResult<any>> {
    return request(`/api/v1/menu`, {
        method: 'DELETE',
        data: {
            ids: bizIds,
        },
    });
}
