import { AuthInfo, clearAuthInfo, setAuthInfo } from '@/utils/authority';
import { Effect, Reducer, getDvaApp, history } from 'umi';
import { accountLogin, mobileLogin } from '@/api/login/service/LoginService';

import { getPageQuery } from '@/utils/utils';
import { stringify } from 'querystring';

export interface SocialPlatform {
    loginUrl: string;
    type: string;
}


export interface LoginStateType {
    status?: 'ok' | 'error';
    type?: string;
}

export interface LoginModelType {
    namespace: string;
    state: LoginStateType;
    effects: {
        login: Effect;
        logout: Effect;
        changeLoginStatus: Effect;
    };
    reducers: {
        changeLoginStatusState: Reducer<LoginStateType>;
    };
}


const Model: LoginModelType = {
    namespace: 'login',

    state: {
        status: undefined,
    },

    effects: {
        * login({ payload }, { call, put }) {
            let response
            if (payload.type === "account") {
                response = yield call(accountLogin, payload);
            } else {
                response = yield call(mobileLogin, payload);
            }
            yield put({
                type: 'changeLoginStatusState',
                payload: response,
            });

            // Login successfully
            if (response.status === 'ok') {
                const urlParams = new URL(window.location.href);
                const params = getPageQuery();
                let { redirect } = params as { redirect: string };
                if (redirect) {
                    const redirectUrlParams = new URL(redirect);
                    if (redirectUrlParams.origin === urlParams.origin) {
                        redirect = redirect.substr(urlParams.origin.length);
                        if (redirect.match(/^\/.*#/)) {
                            redirect = redirect.substr(redirect.indexOf('#') + 1);
                        }
                    } else {
                        window.location.href = '/';
                        return;
                    }
                }
                history.replace(redirect || '/');

                const app = getDvaApp();
                app._store.dispatch({
                    type: 'analysis/clearData',
                });
            }
        },

        logout() {
            clearAuthInfo();
            const { redirect } = getPageQuery();
            // Note: There may be security issues, please note



            if (localStorage.getItem('login-jd') === '1') {
                history.replace({
                    pathname: '/cd',
                    search: stringify({
                        redirect: window.location.href,
                    }),
                });
                localStorage.removeItem("login-jd");
            } else {


                if (window.location.pathname !== '/login' && !redirect) {
                    history.replace({
                        pathname: '/login',
                        search: stringify({
                            redirect: window.location.href,
                        }),
                    });
                }
            }





        },

        * changeLoginStatus({ payload }, { call, put }) {
            yield put({
                type: 'changeLoginStatusState',
                payload: payload,
            });
        }
    },

    reducers: {
        changeLoginStatusState(state, { payload }) {
            const { data } = payload;
            if (payload.code === 200) {
                setAuthInfo(data);
            }
            return {
                ...state,
                status: payload.status,
                type: data.loginType.startsWith('PWD_') ? "account" : "mobile",
            };
        },
    },
};

export default Model;
