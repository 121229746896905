import {Effect, Reducer} from 'umi';

import {queryCurrentUser, queryUsers} from '@/api/system/service/UserService';
import {Role} from "@/api/system/model/Role";
import {EntityId} from '@/api/core/model/ReqData';
import {Group} from '@/api/system/model/Group';
import {Auth} from "@/api/system/model/Auth";

export interface User {
    id: EntityId;
    nickname?: string;
    type: string;
    avatar?: string;
    gender?: string;
    phone?: string;
    description?: string;
    enabled?: boolean;
    locked?: boolean;
    createTime?: string;
    modifyTime?: string;
    roles?: Role[];
    groups?: Group[];
    auths?: Auth[];
    userid?: string;
    unreadCount?: number;
    notifyCount?: number;
    position: string;
}

export interface User_Summary {
    /** 头像 */
    avatar?: string;

    /**
     * 性别
     *  ___ 枚举说明: * MALE: 男 * FEMALE: 女 ___
     */
    gender?: "MALE" | "FEMALE";

    /** @format int64 */
    id?: EntityId;

    /** 姓名 */
    nickname?: string;

    /** 联系电话 */
    phone?: string;

    /** 用户职位 */
    position?: string;

    /**片区*/
    scope?: string

    /**
     * 用户类型
     *  ___ 枚举说明: * SYSTEM: 系统人员 * STAFF: 工作人员 * BREEDER: 饲养员 * VET: 兽医 ___
     */
    type?: "SYSTEM" | "STAFF" | "BREEDER" | "VET";
}

export interface User_Create {
    type: string;
    avatar: string;
    credential: string;
    description: string;
    gender: string;
    groupIds: any[];
    identifier: string;
    nickname: string;
    phone: string;
    roleIds: any[];
    position: string;
    scope?: string
}

export interface User_Modify {
    id: EntityId;
    type: string;
    avatar: string;
    description: string;
    position: string;
    gender: string;
    nickname: string;
    phone: string;
    roleIds: number[];
    groupIds: number[];
    scope?: string
}

export interface User_Patch extends Partial<User_Modify> {
    id: EntityId;
}

export interface UserModelState {
    currentUser?: User;
}

export interface UserModelType {
    namespace: string;
    state: UserModelState;
    effects: {
        fetch: Effect;
        fetchCurrent: Effect;
    };
    reducers: {
        saveCurrentUser: Reducer<UserModelState>;
        changeNotifyCount: Reducer<UserModelState>;
    };
}

const UserModel: UserModelType = {
    namespace: 'user',

    state: {
        currentUser: {} as User,
    },

    effects: {
        * fetch(_, {call, put}) {
            const response = yield call(queryUsers);
            yield put({
                type: 'save',
                payload: response,
            });
        },
        * fetchCurrent(_, {call, put}) {
            const response = yield call(queryCurrentUser);
            yield put({
                type: 'saveCurrentUser',
                payload: response,
            });
        },
    },

    reducers: {
        saveCurrentUser(state, action) {
            return {
                ...state,
                currentUser: action.payload?.data || {},
            };
        },
        changeNotifyCount(state = {}, action) {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    notifyCount: action.payload.totalCount,
                    unreadCount: action.payload.unreadCount,
                } as User,
            };
        },
    },
};

export default UserModel;
