import { EntityId, RespResult } from '@/api/core/model/ReqData';

import { SocialPlatform } from '@/api/login/model/Login';
import request from '@/utils/request';

export interface LoginParamsType {
    loginName: string;
    password: string;
    mobile: string;
    captcha: string;
}

export interface Verification_Code_Type {
    emailOrMobile: string;
    verificationCodeType: string;
}

export async function accountLogin(params: LoginParamsType) {
    return request('/api/v1/account-login', {
        method: 'POST',
        //data: params,
        data: {
            ...params,
            password: window.btoa(params.password),
        },
    });
}

export async function mobileLogin(params: LoginParamsType) {
    return request('/api/v1/mobile-login', {
        method: 'POST',
        data: params,
    });
}

export async function getCaptcha(params: Verification_Code_Type) {
    return request(`/api/v1/send-verification-code`, {
        method: 'POST',
        data: params,
    });
}

export async function getSocialPlatforms(): Promise<RespResult<SocialPlatform[]>> {
    return request(`/api/v1/social/platform`);
}

export async function getAuthInfo(token: string) {
    return request(`/api/v1/login-token-parse`, {
        method: 'POST',
        data: {
            token,
        },
    });
}

export interface ThirdplatformUserBind {
    bid: string;
    loginName: string;
    password: string;
}

export async function bindSocialPlatform(param: ThirdplatformUserBind) {
    return request(`/api/v1/social/bind`, {
        method: 'POST',
        data: param,
    });
}
