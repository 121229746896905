import { AppVersionLast, KfDict, WxJsapiSignature } from '@/api/core/model/Global';
import { RespListResult, RespResult } from '@/api/core/model/ReqData';

import request from '@/utils/request';

export async function queryEnums(): Promise<any> {
    return request('/api/v1/enums');
}
// 获取APP版本最新版本
export async function getAppVersionLast(): Promise<RespResult<AppVersionLast>> {
    return request('/api/v1/app-version/last');
}

export async function queryDicts(dictTypeId: string): Promise<RespListResult<KfDict>> {
    return request(`/api/v1/dict/dict-type-id/${dictTypeId}`);
}

export async function queryAsyncTask(params: any): Promise<any> {
    return request('/api/v1/async-task', {
        params,
    });
}
export async function queryAsyncTaskDetails(paramId: number | string): Promise<any> {
    return request(`/api/v1/async-task/${paramId}`,);
}

// 通知
export async function queryNotices(params: any): Promise<any> {
    return request('/api/v1/notify-remind', {
        params,
    });
}
// 通知 点击项标为已知
export async function noticeRead(paramId: number): Promise<any> {
    return request(`/api/v1/notify-remind/${paramId}/read`, {
        method: 'PUT',
    });
}
// 通知 全部标为已知
export async function noticeReadAll(paramId: number): Promise<any> {
    return request(`/api/v1/notify-remind/read`, {
        method: 'PUT',
    });
}

// 公告
export async function queryAnnouncements(params: any): Promise<any> {
    return request('/api/v1/notify-announce', {
        params,
    });
}
// 公告 点击项标为已读
export async function announcementsRead(paramId: any): Promise<any> {
    return request(`/api/v1/notify-announce/${paramId}/read`, {
        method: 'PUT',
    });
}

// 公告 全部标为已知
export async function announcementsReadAll(paramId: number): Promise<any> {
    return request(`/api/v1/notify-announce/read`, {
        method: 'PUT',
    });
}

export async function createWxMpJsapiTicket(url: string): Promise<RespResult<WxJsapiSignature>> {
    return request(`/api/v1/weixin-server-mp-base/create-jsapi-ticket?url=${encodeURI(url)}`);
}

