/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { RequestOptionsInit, extend } from 'umi-request';
import { getAuthInfo, getToken } from '@/utils/authority';
import { getDvaApp, history } from 'umi';
import { message, notification } from 'antd';

import React from 'react';
import lodash from 'lodash';

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，参数错误。',
    401: '用户没有权限。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = (error: { response: Response; data: any }): Response => {
    const { response, data } = error;

    if (response && response.status) {
        if (response.status === 401) {
            //防抖,避免一堆401弹窗
            const debounced = lodash.debounce(() => {
                const app = getDvaApp();
                if (getAuthInfo()) {
                    notification.error({
                        message: '未登陆或登陆已过时，请重新登陆。',
                    });
                }
                // eslint-disable-next-line no-underscore-dangle
                app._store.dispatch({
                    type: 'login/logout',
                });
            }, 500);
            debounced();

            return data;
        }

        if (response.status === 400 && data.bizcode === 400058 || data.bizcode === 400059) {
            history.push({
                pathname: '/modify-page',
                query: {
                    loginName: data.data.loginName,
                },
            });
        }

        const errorText = data.msg || codeMessage[response.status] || response.statusText;
        let errorDetailText: string | React.ReactNode = '';
        if (response.status === 400 || response.status === 410) {
            if (lodash.isObject(data.data)) {
                errorDetailText = Object.entries(data.data).map((entry, entryIndex) => {
                    return (
                        <div key={entryIndex}>
                            {entry[0]}:{entry[1]}
                        </div>
                    );
                });
            } else {
                errorDetailText = data.data && data.data.toString();
            }
        }
        //const {status, url} = response;
        if (data.bizcode != 400051) {
            notification.error({
                //message: `请求错误 ${status}: ${url}`,
                message: errorText,
                description: errorDetailText && (
                    <div>
                        <div>{errorDetailText}</div>
                    </div>
                ),
            });
        }
    } else if (!response) {
        notification.error({
            description: '您的网络发生异常，无法连接服务器',
            message: '网络异常',
        });
    }
    return data;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
    errorHandler, // 默认错误处理
    prefix: REQUEST_BASE_URL,
    credentials: 'omit', // 默认请求是否带上cookie
});

request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
    const token = getToken();
    let newOptions = options;
    if (token) {
        //如果有token 就走token逻辑
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        newOptions = { ...options, headers: headers };
    }
    return {
        url: url,
        options: { ...newOptions },
    };
});

export default request;
