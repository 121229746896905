import type { PageQueryParams, RespPageResult, RespResult } from '@/api/core/model/ReqData';

import type { AgeDataType } from '../model/Age';
import type { BaseDataType } from "@/api/analysis/model/Base";
import { CurrentUserCenter } from '../model/CurrentUserCenter';
import type { GeneralBaseDataType } from '../model/GeneralBase';
import type { ImgDataType } from '../model/Imgs';
import type { OverviewDataType } from "@/api/analysis/model/Overview";
import type { SexDataType } from '../model/Sex';
import request from '@/utils/request';

export interface APageQueryParams extends Partial<PageQueryParams> {
    searchText?: string;
}

export async function queryOverviews(params?: APageQueryParams): Promise<RespPageResult<OverviewDataType>> {
    return request('/api/v1/sta/info/number-status', {
        params,
    });
}
export async function queryOverviewsNew(params?: APageQueryParams): Promise<RespPageResult<OverviewDataType>> {
    return request('/api/v1/sta/info/large-screen', {
        params,
    });
}
export async function queryBases(params?: APageQueryParams): Promise<RespResult<BaseDataType>> {
    return request('/api/v1/sta/info/number-base-name', {
        params,
    });
}
export async function queryAges(params?: APageQueryParams): Promise<RespPageResult<AgeDataType>> {
    return request('/api/v1/sta/info/number-age', {
        params,
    });
}
export async function querySexs(params?: APageQueryParams): Promise<RespPageResult<SexDataType>> {
    return request('/api/v1/sta/info/number-sex', {
        params,
    });
}
export async function queryGeneralBases(params?: APageQueryParams): Promise<RespPageResult<GeneralBaseDataType>> {
    return request('/api/v1/sta/growth/all-status', {
        params,
    });
}
export async function queryAgeCenters(params?: APageQueryParams): Promise<RespPageResult<GeneralBaseDataType>> {
    return request('/api/v1/sta/info/number-age-center-index', {
        params,
    });
}
export async function queryImgs(params?: APageQueryParams): Promise<RespPageResult<ImgDataType>> {
    return request('/api/v1/info-datafile', {
        params,
    });
}
export async function queryGroupBases(params?: APageQueryParams): Promise<RespPageResult<ImgDataType>> {
    return request('/api/v1/group/base', {
        params,
    });
}
export async function queryBasesbContinent(params?: APageQueryParams): Promise<RespPageResult<ImgDataType>> {
    return request('/api/v1/sta/info/number-base-continent', {
        params,
    });
}
export async function queryPark(params?: APageQueryParams): Promise<RespPageResult<ImgDataType>> {
    return request('/api/v1/sta/info/number-park', {
        params,
    });
}
/** 组查询当前用户所属中心*/
export async function queryCurrentUserCenter(): Promise<RespResult<CurrentUserCenter>> {
    return request('/api/v1/group/center');
}






