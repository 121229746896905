import { Effect, Reducer } from "@@/plugin-dva/connect";

import { EntityId } from "@/api/core/model/ReqData";
import { MenuDataItem } from "@ant-design/pro-layout";
import { TreeNode } from "@/api/core/model/Connect";
import { queryFrMenuTree } from "@/api/system/service/MenuService";
import { toIconComponent } from "@/utils/utils";
import { treeWalk } from "@/utils/treeUtils";

export interface Menu {
    id: EntityId;
    parentId?: number;
    code: string;
    name: string;
    path: string;
    icon?: string;
    type: string;
    createTime?: string;
    modifyTime?: string;
    seq?: number;
}

export interface Menu_TreeNode extends Menu, TreeNode {
    id: EntityId;
    children?: Menu_TreeNode[];
}

export interface Menu_Create {
    path: string;
    icon: string;
    code: string;
    name: string;
    parentId: number;
    seq: number;
    type: string;
}

export interface Menu_Modify extends Menu_Create {
    id: EntityId;
}

export interface Menu_Patch extends Partial<Menu_Create> {
    id: EntityId;
}


export interface MenuModelState {
    menus: MenuDataItem[];
}

export interface MenuModelType {
    namespace: string;
    state: MenuModelState;
    effects: {
        fetchMenus: Effect;
    };
    reducers: {
        saveMenus: Reducer<MenuModelState>;
    };
}


type MenuDataItemTreeNode = MenuDataItem & TreeNode;



const MenuModel: MenuModelType = {
    namespace: 'menu',

    state: {
        menus: [],
    },

    effects: {
        * fetchMenus(_, { call, put }) {
            const response = yield call(queryFrMenuTree);
            yield put({
                type: 'saveMenus',
                payload: response,
            });
        },
    },

    reducers: {
        saveMenus(state, action) {
            const menus: MenuDataItem[] = action.payload?.data || [];
            const treeNodes = menus as MenuDataItemTreeNode[];
            treeWalk(treeNodes, (item) => {
                if (!item.authority) {
                    item.authority = []
                }
                item.authority = [...item.authority, "ROLE_SUPER_ADMIN"]

                if (item.icon) {
                    const { icon } = item;
                    if (typeof icon === "string") {
                        item.icon = toIconComponent(icon);
                    }
                }
            })
            return {
                ...state,
                menus,
            };
        },
    },
};

export default MenuModel;
