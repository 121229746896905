/**
 * 遍历树
 */
import {TreeNode} from "@/api/core/model/Connect";

export const treeCopy = <T extends TreeNode, R extends TreeNode>(tree: T[], transform: (node: T) => R, filter: (node: T) => boolean) => {
  return tree
    .filter(filter)
    .map((node) => {
      return innerTreeCopy(node, transform, filter);
    })
}

function innerTreeCopy<T extends TreeNode, R extends TreeNode>(treeNode: T, transform: (node: T) => R, filter: (node: T) => boolean) {
  const r = transform(treeNode);
  if (treeNode.children) {
    r.children = treeNode.children.filter(child => filter(child as T)).map((child) => {
      return innerTreeCopy(child as T, transform, filter);
    });
  }
  return r;
}

export const treeWalk = <T extends TreeNode>(tree: T[], fn: (node: T) => void) => {
  treeCopy(tree, (n) => {
    fn(n);
    return n;
  }, (n) => true);
}

export const treeFind = <T extends TreeNode>(tree: T[], fn: (node: T) => boolean): T | undefined => {
  let r = tree.find(fn)
  if (r) {
    return r;
  }
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children && node.children.length > 0) {
      r = treeFind(node.children as T[], fn);
      if (r) {
        return r;
      }
    }
  }
  return undefined;
}

export const treeFilter =  <T extends TreeNode>(tree: T[], filter: (node: T) => boolean) => {


}
