// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/opt/buildagent/work/ce43cb65eea958fd/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/modify-page",
    "name": "修改密码",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__admin__user__modifyPage' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/system/admin/user/modifyPage'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/large-screen",
    "name": "熊猫大屏",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LargeScreen' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/LargeScreen'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/cd",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__JdLayout' */'/opt/buildagent/work/ce43cb65eea958fd/src/layouts/JdLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "登录",
        "path": "/cd",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__loginJd' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/loginJd'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/opt/buildagent/work/ce43cb65eea958fd/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "登录",
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "社交登录",
        "path": "/login/social",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__SocialLogin' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/login/SocialLogin'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/opt/buildagent/work/ce43cb65eea958fd/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/opt/buildagent/work/ce43cb65eea958fd/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/dashboard",
            "exact": true
          },
          {
            "path": "/dashboard",
            "name": "工作台",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/Dashboard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system-admin/user",
            "name": "用户管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__admin__user' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/system/admin/user'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system-admin/group",
            "name": "组织机构管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__admin__group' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/system/admin/group'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system-admin/role",
            "name": "角色管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__admin__role' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/system/admin/role'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system-admin/perm",
            "name": "权限管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/Dashboard'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system-admin/menu",
            "name": "菜单管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__admin__menu' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/system/admin/menu'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/news",
            "name": "圈养大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__news' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/news'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/captivity-panda",
            "name": "圈养大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__captivityPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/captivityPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/rightout-panda",
            "name": "权出大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__rightoutPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/rightoutPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/dead-panda",
            "name": "归档大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__deadPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/deadPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/dead-prematurely-panda",
            "name": "归档大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__deadPrematurelyPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/deadPrematurelyPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/rewilded-panda",
            "name": "放归大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__rewildedPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/rewildedPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/archives/unmanaged-panda",
            "name": "未纳管大熊猫",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__archives__panda__unmanagedPanda' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/archives/panda/unmanagedPanda'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/cub/panda-rearing",
            "name": "幼年大熊猫生长发育指标",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaCubRearing' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaCubRearing'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/cub/panda-feed",
            "name": "幼年大熊猫饲喂记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaCubFeed' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaCubFeed'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/cub/panda-excretion",
            "name": "幼年大熊猫排泄记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaCubExcretion' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaCubExcretion'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/cub/panda-physiological-record",
            "name": "幼年大熊猫环境变化记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaCubPhysiologicalRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaCubPhysiologicalRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/adult/panda-rearing",
            "name": "断奶后大熊猫生长发育指标",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaAdultRearing' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaAdultRearing'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/adult/panda-feed",
            "name": "断奶后大熊猫饲喂记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaAdultFeed' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaAdultFeed'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/adult/panda-rearing-abundant",
            "name": "断奶后大熊猫丰容情况记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaAdultRearingAbundant' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaAdultRearingAbundant'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/adult/panda-rearing-train",
            "name": "断奶后大熊猫爱心培训",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaAdultRearingTrain' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaAdultRearingTrain'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/panda-rearing-env",
            "name": "饲养环境记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaRearingEnv' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaRearingEnv'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/panda-rearing-disinfection",
            "name": "饲养环境消毒记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaRearingDisinfection' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaRearingDisinfection'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/rearing/panda-batch-import",
            "name": "批量导入",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__rearing__pandaBatchImport' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/rearing/pandaBatchImport'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-vet-patrolled-record",
            "name": "兽医巡查工作记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaVetPatrolledRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaVetPatrolledRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-treat-record",
            "name": "疾病治疗工作记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaTreatRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaTreatRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-exam-record",
            "name": "体检及预防工作记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaExamRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaExamRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-hocus",
            "name": "麻醉记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaHocus' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaHocus'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-research-apply",
            "name": "采样及科研试验工作申请",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaResearchApply' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaResearchApply'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-research-record",
            "name": "采样及科研试验工作记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaResearchRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaResearchRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/panda-vaccination",
            "name": "疫苗注射表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaVaccination' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaVaccination'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/chip-injection",
            "name": "芯片注射表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaChipInjection' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaChipInjection'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/epidemic-situation",
            "name": "疫源疫病监测管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaEpidemicSituation' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaEpidemicSituation'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/health/feed-anthelmintic",
            "name": "驱虫药投喂表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__health__pandaFeedAnthelmintic' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/health/pandaFeedAnthelmintic'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/breed/panda-estrus-record",
            "name": "发情记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__breed__pandaEstrusRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/breed/pandaEstrusRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/breed/panda-breed-record",
            "name": "配种记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__breed__pandaBreedRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/breed/pandaBreedRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/breed/panda-pregnancy-record",
            "name": "怀孕记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__breed__pandaPregnancyRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/breed/pandaPregnancyRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/breed/panda-newborn-record",
            "name": "产仔记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__breed__pandaNewbornRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/breed/pandaNewbornRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/breed/panda-sperm-record",
            "name": "采精记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__breed__pandaSpermRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/breed/pandaSpermRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/trans/panda-trans-record",
            "name": "转运记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__trans__pandaTransRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/trans/pandaTransRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/panda-admin/wran/panda-warn-record",
            "name": "预警记录",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__panda__wran__pandaWarnRecord' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/panda/wran/pandaWarnRecord'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/rearing-stats/rs1",
            "name": "日常饲养报表-总体评估",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__rearingStats__RearingStats1' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/rearingStats/RearingStats1'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/rearing-stats/rs2",
            "name": "日常饲养报表-总体饲喂",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__rearingStats__RearingStats2' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/rearingStats/RearingStats2'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/rearing-stats/rs3",
            "name": "日常饲养报表-月报",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__rearingStats__RearingStats3' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/rearingStats/RearingStats3'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/health-stats",
            "name": "健康管理报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__healthStats' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/healthStats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/breed-stats",
            "name": "繁殖管理报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__breedStats' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/breedStats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/wran-stats",
            "name": "预警统计报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__wranStats' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/wranStats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/trans-stats",
            "name": "转运统计报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__transStats' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/transStats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/stats/growth-stats",
            "name": "转运统计报表",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__stats__growthStats' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/stats/growthStats'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/knowledge/knowledge-repository",
            "name": "知识库",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__knowledge__knowledgeRepository' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/knowledge/knowledgeRepository'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/buildagent/work/ce43cb65eea958fd/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
