import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import moment from "moment";
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

const persistConfig = {
  key: 'redux-storage',
  storage,
  whitelist: ['global'],
  stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};

const persistEnhancer: any = () => (createStore: any) => (reducer: any, initialState: any, enhancer: any) => {
  const store = createStore(persistReducer(persistConfig, reducer), initialState, enhancer);
  const persist = persistStore(store, null);
  return {...store, persist};
};

export const dva = {
  config: {
    onError(err: ErrorEvent) {
      err.preventDefault();
      console.error(err.message);
    },
    extraEnhancers: [persistEnhancer()]
  },
};

