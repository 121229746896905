import type { EntityId, PageQueryParams, RespPageResult, RespResult } from '@/api/core/model/ReqData';
import type {
    Panda,
    Panda_ChangeLeader_Modify,
    Panda_ChangeRecordStatus_Modify,
    Panda_Create,
    Panda_DeathReason_Modify,
    Panda_Detail,
    Panda_Modify,
    Panda_PandaType_Modify,
    Panda_Patch,
    Panda_PregnancyStatus_Modify
} from "@/api/panda/archives/model/Panda";
import { Panda_ChangeNewbornIdDto, Panda_Enclosur_Modify } from "@/api/panda/archives/model/Panda";

import request from '@/utils/request';

export interface PandaPageQueryParams extends Partial<PageQueryParams> {
    searchText?: string;
    recordStatus?: string;
    groupId?: number;
    fatherId?: number;
    motherId?: number;
    breedingBaseId?: EntityId;
    ids?: EntityId[];
    sex?: "MALE" | "FEMALE";
    startBirthday?: string;
    endBirthday?: string;
    ignoreGroupPerm?: boolean;
    breed?: boolean;
    diePrematurely?: boolean;
    existNewbornId?: boolean;
    startDeathDate?: string
    endDeathDate?: string
    dateRange?: string[]
}

export async function queryPandas(params: PandaPageQueryParams): Promise<RespPageResult<Panda>> {
    return request('/api/v1/info', {
        params,
    });
}

export async function queryPanda(bizId: EntityId): Promise<RespResult<Panda_Detail>> {
    return request(`/api/v1/info/${bizId}`);
}

export async function createPanda(entity: Panda_Create): Promise<RespResult<any>> {
    return request(`/api/v1/info`, {
        method: 'POST',
        data: entity,
    });
}

export async function createUnmanagedPanda(entity: Panda_Create): Promise<RespResult<any>> {
    return request(`/api/v1/info/unmanaged-create`, {
        method: 'POST',
        data: entity,
    });
}


export async function modifyPanda(entity: Panda_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/${entity.id}`, {
        method: 'PUT',
        data: entity,
    });
}

export async function modifyUnmanagedPanda(entity: Panda_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/unmanaged-create/${entity.id}`, {
        method: 'PUT',
        data: entity,
    });
}


export async function patchPanda(entity: Panda_Patch): Promise<RespResult<any>> {
    return request(`/api/v1/info/${entity.id}`, {
        method: 'PATCH',
        data: entity,
    });
}

export async function deletePanda(bizId: EntityId): Promise<RespResult<any>> {
    return request(`/api/v1/info/${bizId}`, {
        method: 'DELETE'
    });
}

export async function deletePandas(bizIds: EntityId[]): Promise<RespResult<any>> {
    return request(`/api/v1/info`, {
        method: 'DELETE',
        data: {
            ids: bizIds
        },
    });
}
export async function changeNewbornId(dto: Panda_ChangeNewbornIdDto): Promise<RespResult<any>> {
    return request(`/api/v1/info/change-newborn-id/${dto.id}`, {
        method: 'PUT',
        data: dto,
    });
}

export async function changeRecordStatus(bizId: EntityId, dto: Panda_ChangeRecordStatus_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/change-record/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}

export async function changeLeader(bizId: EntityId, dto: Panda_ChangeLeader_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/change-leader/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}
export async function changePandaType(bizId: EntityId, dto: Panda_PandaType_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/panda-type/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}
export async function changePregnancyStatus(bizId: EntityId, dto: Panda_PregnancyStatus_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/pregnancy-status/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}
export async function changeEnclosur(bizId: EntityId, dto: Panda_Enclosur_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/change-enclosure/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}
export async function changeDeathReason(bizId: EntityId, dto: Panda_DeathReason_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/info/change-death-reason/${bizId}`, {
        method: 'PUT',
        data: dto,
    });
}
