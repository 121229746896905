import request from '@/utils/request';
import {EntityId, PageQueryParams, RespPageResult, RespResult} from '@/api/core/model/ReqData';
import {User, User_Create, User_Modify, User_Patch} from '@/api/system/model/User';

export interface UserPageQueryParams extends Partial<PageQueryParams> {
    ids?: EntityId[];
    searchText?: string;
    gender?: string;
    enabled?: boolean;
    locked?: boolean;
    groupId?: number;
    bizGroupCode?: string;
    roleNameOrCode?: string;
    roleCodes?: string[];
    types?: string[];
    ignoreGroupPerm?: boolean;
}

export async function queryUsers(params: UserPageQueryParams): Promise<RespPageResult<User>> {
    return request('/api/v1/user', {
        params,
    });
}

export async function queryUser(bizId: EntityId): Promise<RespResult<User>> {
    return request(`/api/v1/user/${bizId}`);
}

export async function queryCurrentUser(): Promise<RespResult<User>> {
    return request('/api/v1/current-user');
}

export async function createUser(entity: User_Create): Promise<RespResult<any>> {
    return request(`/api/v1/user`, {
        method: 'POST',
        data: entity,
    });
}

/*export async function createUsers(entitys: User_Create[]): Promise<RespResult<any>> {
  const formData = new FormData();
  formData.append('dtos', JSON.stringify(entitys));
  return request(`/api/v1/user/import`, {
    method: 'POST',
    data: formData,
  });
}*/


export async function modifyUser(entity: User_Modify): Promise<RespResult<any>> {
    return request(`/api/v1/user/${entity.id}`, {
        method: 'PUT',
        data: entity,
    });
}

export async function patchUser(entity: User_Patch): Promise<RespResult<any>> {
    return request(`/api/v1/user/${entity.id}`, {
        method: 'PATCH',
        data: entity,
    });
}

export async function deleteUser(bizId: EntityId): Promise<RespResult<any>> {
    return request(`/api/v1/user/${bizId}`, {
        method: 'DELETE',
    });
}

export async function deleteUsers(bizIds: EntityId[]): Promise<RespResult<any>> {
    return request(`/api/v1/user`, {
        method: 'DELETE',
        data: {
            ids: bizIds,
        },
    });
}


export async function enableUser(bizId: EntityId): Promise<RespResult<any>> {
    return request(`/api/v1/user/${bizId}/enable`, {
        method: 'PUT',
    });
}

export async function disableUser(bizId: EntityId): Promise<RespResult<any>> {
    return request(`/api/v1/user/${bizId}/disable`, {
        method: 'PUT',
    });
}
